<template>
  <div id="app">
    <div class="container">
      <h1 class="title">META DECIPHER</h1>
      <p class="binary">01001101 01000101 01010100 01000001 00100000 01000100 01000101 01000011 01001001 01010000 01001000 01000101 01010010</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  head: {
    title: 'META DECIPHER - Inteligência de Dados, Comunicação e Inovação',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: 'Meta Decipher: Líderes em inteligência de dados, comunicação e inovação. Descubra soluções de ponta para seus desafios de negócios.'
      }
    ]
  }
}
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.container {
  text-align: center;
  background-color: #ffffff;
  padding: 40px 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2.5em;
  color: #333;
  font-weight: bold;
  letter-spacing: 2px;
}

.binary {
  font-size: 1.2em;
  color: #888;
  letter-spacing: 1px;
}

</style>

